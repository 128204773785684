import { Button, Dialog } from '@mui/material';
import cn from 'classnames';
import Parser from 'html-react-parser';
import { t } from 'i18next';
import moment, { Moment } from 'moment';
import { Resizable } from 're-resizable';
import { useEffect, useState } from 'react';
import Frame from 'react-frame-component';
import { useDispatch, useSelector } from 'react-redux';
import { Reservation } from '../../../models/reservation';
import { RoomSeat } from '../../../models/room';
import { Shift } from '../../../models/shift';
import { GetUserRoomsImage, getRoomseatsForRoom } from '../../store/room/user-room/user-room.actions';
import { RootState } from '../../store/store';
import style from './roomseatsmapdialog.module.scss';

export type RoomseatsMapDialogType = {
    shift: Shift,
    date: Moment,
    isOpen: boolean,
    onAccept: (roomSeat: RoomSeat, pkOldReservation:number) => void,
    onDismiss: () => void,
}

const RoomseatsMapDialog: React.FC = () => {
    const { roomseatsMap } = useSelector((state: RootState) => state.dialogs)
    const { roomSeatsList, roomReservations, selectedRoom, imageHasLoaded } = useSelector((state: RootState) => state.room.userRoom)
    const meUser = useSelector((state: RootState) => state.auth.user)
    const [open, setOpen] = useState(roomseatsMap.isOpen)
    const [reservations, setReservations] = useState<Reservation[]>([])
    const [selected, setSelected] = useState<number>(0);
    const [zoomLevel, setZoomLevel] = useState(1)
    const dispatch = useDispatch()

    const handleClose = () => {
        setSelected(0)
        setZoomLevel(1)
        setOpen(false)
    }

    const handleAccept = () => {
        setSelected(0);
        setZoomLevel(1)
        setOpen(false);

        const pkOldReservation = reservations?.find(r=> r.fkusers === meUser.pkusers);
        roomseatsMap.onAccept(roomSeatsList.find(r=>r.pkroomseats === selected), pkOldReservation.pkreservations);
    }

    const handleSeatClicked = (pkroomseats, available:boolean) => {
        if(available){
            setSelected(pkroomseats)
        }
    }

    useEffect(() => {
        setOpen(roomseatsMap.isOpen);
        setZoomLevel(1)
    }, [roomseatsMap])

    useEffect(() => {
        if(selectedRoom?.pkrooms)
            dispatch(getRoomseatsForRoom(selectedRoom?.pkrooms))
    }, [selectedRoom])

    useEffect(() => {
        const res = roomReservations.find(rr => rr.pkshifts === roomseatsMap.shift?.pkshifts)?.reservations.filter(r => moment(roomseatsMap.date).format("L").toString() === moment(r.reservationdate).format("L").toString())
        setReservations(res)
    }, [roomReservations, roomseatsMap])

    //Caricamento delle immagini
    useEffect(() => {
        if(!imageHasLoaded){
            dispatch(GetUserRoomsImage())
            // dispatch(getImageHasLoadedSuccess(false))
        }
    },[dispatch, selectedRoom])

    const [{ width, height }, setSize] = useState({
        width: 750,
        height: 350,
      })

    const head = Parser(document.head.innerHTML).filter(
        element => typeof element === 'object',
    )
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={'md'}
            fullWidth>
                <div className={cn(style["dialog-container"])}>
                    <div className={cn(style["stickyTopLeft"])}>
                        <h5 className={cn(style["primary"])}>{t("Choose your seat in")} <b>{(selectedRoom?.roomname)}</b></h5>
                        <input
                            type="range"
                            min={100}
                            max={1000}
                            value={(zoomLevel * 100).toFixed(0)}
                            onChange={event => setZoomLevel(parseFloat(event.target.value) / 100)
                            }
                        />
                    </div>
                    <Resizable
                        enable={{
                        top: false,
                        right: true,
                        bottom: true,
                        left: false,
                        topRight: false,
                        bottomRight: true,
                        bottomLeft: false,
                        topLeft: false,
                        }}
                        size={{
                        width: width * zoomLevel,
                        height: height * zoomLevel,
                        }}
                        minWidth={750 * zoomLevel}
                        minHeight={350 * zoomLevel}
                        onResizeStop={(event, direction, ref, delta) => {
                        setSize({
                            width: width + delta.width,
                            height: height + delta.height,
                        })
                        }}
                    ><Frame
                    head={head}
                    style={{
                      width: width,
                      height: height,
                      border: 'none',
                      transform: `scale(${zoomLevel})`,
                      transformOrigin: `top left`,
                    }}
                  >
                    <div
                        style={{display: "flex", justifyContent: "center"}}>
                        <img
                            useMap='seatsMap'
                            src={"data:image/" + (selectedRoom?.mapname?.split(".")[1] === "svg" ? "svg+xml" : selectedRoom?.mapname?.split(".")[1]) + ";base64," + selectedRoom?.mapattachment}
                            style={{width: "750px", height: "350px"}}
                            >
                        </img>
                    </div>
                    <div
                        style={{background: "transparent", display: "flex", justifyContent: "center", marginTop: "-350px"}}>
                        <svg
                            viewBox={selectedRoom?.mapviewbox ?? '0 0 750 350'}
                            style={{width: "750px", height: "350px"}}>
                            {
                                roomSeatsList.length > 0 ?
                                roomSeatsList.map(rs => (
                                    <g>
                                        <rect
                                            key={rs.pkroomseats}
                                            x={rs.coordinates.split(',')[0]}
                                            y={rs.coordinates.split(',')[1]}
                                            transform={rs.inclination ? `rotate(${rs.inclination})` : ''}
                                            width={parseInt(rs.coordinates.split(',')[2]) - parseInt(rs.coordinates.split(',')[0])}
                                            height={parseInt(rs.coordinates.split(',')[3]) - parseInt(rs.coordinates.split(',')[1])}
                                            onClick={() => handleSeatClicked(rs.pkroomseats, (rs.available && (reservations?.findIndex(r => r.fkroomseats === rs.pkroomseats) < 0)))}
                                            className={cn(style["hitbox"], style["pointer"], selected === rs.pkroomseats ? style["selected"] : (!rs.available ? style["unavailable"] : (reservations?.findIndex(r => r.fkroomseats === rs.pkroomseats) >= 0 ? style["reserved"] : style["free"])))}
                                            fill='lightblue'
                                            stroke='darkblue'
                                            rx="8"
                                        />
                                        <text
                                            className={cn(style["rectText"])}
                                            dominantBaseline="middle"
                                            x={parseInt(rs.coordinates.split(',')[0]) + (parseInt(rs.coordinates.split(',')[2]) - parseInt(rs.coordinates.split(',')[0]))/2}
                                            y={parseInt(rs.coordinates.split(',')[1]) + (parseInt(rs.coordinates.split(',')[3]) - parseInt(rs.coordinates.split(',')[1]))/2}>
                                                {
                                                    reservations?.find(r => r.fkroomseats === rs.pkroomseats)?.utenti
                                                }
                                        </text>
                                    </g>
                                ))
                                :
                                <></>
                            }
                        </svg>
                    </div>
                    </Frame>
                    </Resizable>
                        <div className={cn(style["stickyBottomRight"],"col d-flex justify-content-end")}>
                            <Button
                                className={cn([style["btnClose"]], "me-2")}
                                onClick={handleClose}>
                                    {t("Close")}
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleAccept}
                                disabled={selected === 0}>
                                {t("Reserve selected seat")}
                            </Button>
                        </div>
                </div>
        </Dialog>
    );
};

export default RoomseatsMapDialog;
