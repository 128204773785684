import { faPen, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next/";
import { useDispatch, useSelector } from "react-redux";
import { DialogMessageType } from "../../../../core/components/dialog/dialog-message";
import { getDialogMessage } from "../../../../core/components/dialog/store/dialogMessage.store";
import { ControlledSelect, ControlledTextField } from "../../../../core/components/mui-input/MuiInput";
import { getHoursShifts } from "../../../../core/store/room/administration-room/administration-room.selector";
import { RootState } from "../../../../core/store/store";
import { Shift } from '../../../../models/shift';
import style from "../../rooms-management.module.scss";


interface RoomShiftFormProps{
    setShifts: React.Dispatch<React.SetStateAction<Shift[]>>;
    shifts: Shift[];
    typeRoom: ("New" | "Duplicate" | "Update");
}

interface NewShift{
    shiftname: string;
    starttime: string;
    endtime: string;
}


export const RoomShiftForm: FC<RoomShiftFormProps> = ({ setShifts, shifts,typeRoom }) => {
    const {t} = useTranslation();
    const [action, setAction] = useState<"New" | "Edit">("New");
    const [prevState, setPrevState] = useState<Shift>();
    const dispatch = useDispatch();
    const hoursShifts = useSelector(getHoursShifts);
    const { user } = useSelector((state: RootState) => state.auth)
    const hoursOption = hoursShifts.map((hour)=>(
        <MenuItem
        key={hour}
        value={hour}
        >
            {hour}
        </MenuItem>
    ));

    const { control, handleSubmit, reset}
    = useForm<NewShift>({
        mode: "all",
        reValidateMode: "onSubmit",
        defaultValues: {
            shiftname: ''
        }
    })


    useEffect(()=>{
        if(typeRoom==="New" && shifts.length===0)
        {
        const mourningShift: Shift = {
            shiftname: t("Morning"),
            endtime: "08:00",
            starttime: "13:00"
        }
        const eveningShift: Shift = {
            shiftname: t("Afternoon"),
            endtime: "08:00",
            starttime: "13:00"
        }
        setShifts(prevState=>([...prevState, mourningShift]));
        setShifts(prevState=>([...prevState, eveningShift]));
    }
    }, [])


    const onSubmit = handleSubmit(data => {
        if((data.starttime >= data.endtime && data.endtime !== "00:00") || data.starttime === data.endtime){
            let message : DialogMessageType = {
                dialogMsg :(<div>{t("StartimeGreaterEndtime")}</div>),
                dialogTitle : t("Attention"),
                dialogAcceptButton: t("Ok"),
                isOpen : true,
                onDismiss: () => {},
                onAccept: () => {}
            }
            dispatch(getDialogMessage(message));
        }else if(action === "New"){
            setShifts(prevState=>([...prevState, {...data, pkshifts:0}]));
            reset({shiftname:"", endtime:"", starttime:""});
            setAction("New");
        }else if(action ==="Edit"){
            setShifts(shifts.filter(item=> item !== prevState));
            if(prevState.pkshifts === 0){
                setShifts(prevState=>([...prevState, {...data, pkshifts:0}]));
            }else{
                const newShift: Shift = {
                    shiftname: data.shiftname,
                    endtime: data.endtime,
                    starttime: data.starttime,
                    pkshifts: prevState.pkshifts,
                    fkrooms: prevState.fkrooms,
                    isdeleted: prevState.isdeleted,
                    doi: prevState.doi,
                    dou: prevState.dou,
                    reservations: prevState.reservations,
                    booked: prevState.booked,
                }
                setShifts(prevState=>([...prevState, newShift]));
            }
            reset({shiftname:"", endtime:"", starttime:""});
            setAction("New");
        }
    });

    const editShift = (shift: Shift)=>{
        reset({shiftname: shift.shiftname, starttime:shift.starttime, endtime:shift.endtime});
        setAction("Edit");
        setPrevState(shift);
    }

    function onDeleteAlert(Shifttodelete:Shift[]){
        let message : DialogMessageType = {
            dialogMsg :(<div>{t("Delete shift confirm")}?</div>),
            dialogTitle : t("Attention"),
            dialogAcceptButton: t("Yes"),
            dialogDismissButton: t("Cancel"),
            isOpen : true,
            onDismiss: () => {},
            onAccept: () => {setShifts(Shifttodelete)}
        }
        dispatch(getDialogMessage(message));

    }

    return(
        <Grid container sx={{ height:"37vh", overflow:"auto", paddingTop:"1%"}}>
        {/* Form */}
            <Grid container spacing={2} style={{visibility: user.functionalities.findIndex(f => f.nome === "Add_shift" || f.nome === "Modify_shift") === -1 ? "hidden" : "visible"}}>
                <Grid item xs={12} sm={12} md={12}>
                    <div data-joyride="shift-description">
                        <ControlledTextField
                            size="small"
                            label={t("Shift Name")}
                            fullWidth
                            required
                            control={(control as any)}
                            name={"shiftname"}
                            rules={{required: {value: true, message: t("Field Required")}, maxLength:30}}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <ControlledSelect
                        size="small"
                        control={(control as any)}
                        name="starttime"
                        label={t("From Time")}
                        fullWidth
                        required
                        options={hoursOption}
                        rules={{required: {value: true, message: t("Field Required")}}}
                    />

                </Grid >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <ControlledSelect
                        size="small"
                        control={(control as any)}
                        name="endtime"
                        label={t("To Time")}
                        fullWidth
                        required
                        options={hoursOption}
                        rules={{required: {value: true, message: t("Field Required")}}}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Button sx={{height:"auto", padding: "2px 25px"}} variant="contained" onClick={()=>onSubmit()} className={cn(style["button-primary"], style["h-70"])} style={{visibility: ((action === "New" && user.functionalities.findIndex(f => f.nome === "Add_shift") === -1) || (action === "Edit" && user.functionalities.findIndex(f => f.nome === "Modify_shift") === -1)) ? "hidden" : "visible"}}>
                        {action==="New" && t("Add Shift")}
                        {action==="Edit" && t("Edit Shift")}
                    </Button>
                </Grid>
            </Grid>

            {/* Table */}
            <Box
                component="div"
                width="100%"
                sx={{height:"17vh", overflow:"auto", paddingTop:"1%"}}
            >
                <table className="table table-hover table-responsive">
                    <thead>
                        <tr>
                            <th scope="col"><Typography fontWeight={600}> {t("Shift Name")} </Typography></th>
                            <th scope="col"><Typography fontWeight={600}> {t("From Time")} </Typography></th>
                            <th scope="col"><Typography fontWeight={600}> {t("To Time")} </Typography></th>
                            <th scope="col">#</th>
                        </tr>
                    </thead>

                    <tbody>
                        {shifts.slice().sort((a, b) => a.starttime.localeCompare(b.starttime)).map((shift: Shift)=>(
                            <tr>
                                <td><Typography> {shift.shiftname} </Typography></td>
                                <td><Typography> {shift.starttime} </Typography></td>
                                <td><Typography> {shift.endtime} </Typography></td>
                                <td>
                                    <FontAwesomeIcon icon={faPen} style={{ cursor: "pointer", visibility: user.functionalities.findIndex(f => f.nome === "Modify_shift") === -1 ? "hidden" : "visible" }} onClick={()=>editShift(shift)}/> &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: "pointer", visibility: user.functionalities.findIndex(f => f.nome === "Delete_shift") === -1 ? "hidden" : "visible" }} onClick={()=>{onDeleteAlert(shifts.filter(item=> item !== shift))}}/>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </Box>
        </Grid>
    );
}
